*:not(.material-icons) {
  font-family: 'Space Grotesk', sans-serif !important;
}

/* Override the markdown math expression size to be the same as the rest of the text
TODO: the above !important rule overrides the KaTeX_Math and KaTeX_Main fonts,
 that should not happen, needs to be fixed
*/
span.katex {
  font-size: 1rem;
}

html {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(../public/background.svg);
  background-color: #142945;
  background-size: cover;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

span.swim-in {
  animation: swim-in;
  animation-duration: 2s;
}

@keyframes swim-in {
  0% {
    visibility: hidden;
    transform: scale(0.2)
  }

  90% {
    visibility: hidden;
    transform: scale(0.2)
  }

  100% {
    visibility: visible;
    transform: scale(1)
  }
}

.taskOverviewGrid::-webkit-scrollbar {
  overflow-y: scroll;
  overflow-x: auto;
}

.scroll-container::-webkit-scrollbar {
  width: 5px;
  background: white;
  border-radius: 12px;
}

.scroll-container::-webkit-scrollbar-thumb {
  width: 5px;
  background: rgb(41, 153, 188);
  border-radius: 12px;
}

.lockedPlanet{
  filter: brightness(0.25);
}

.unlockedPlanet{
  filter: blur(2px);
}